// =================================================
// LAYOUT STYLES
// =================================================

.outer {
  display: block;
  position: relative;
}

.inner {
  display: block;
  width: 100%;
  margin: 0 auto;
}

.inner--sm {
  padding: 0 $padding--md;

    @include mq(screen--sm) {
      width: $screen--sm;
    }

    @include mq(screen--lg) {
      padding: 0 $padding--lg;
    }

}

.inner--md {
  padding: 0 $padding--md;

    @include mq(screen--sm) {
      width: $screen--sm;
    }

    @include mq(screen--lg) {
      padding: 0 $padding--lg;
    }

    @include mq(screen--xl) {
      width: $screen--md;
    }

}

.inner--lg {
  padding: 0 $padding--md;

    @include mq(screen--lg) {
      width: $screen--lg;
      padding: 0 $padding--lg;
    }

}

.inner--xl {
  padding: 0 $padding--md;

    @include mq(screen--lg) {
      padding: 0 $padding--lg;
    }

    @include mq(screen--xl) {
      width: $screen--xl;
    }

}

.inner--xxl {
  padding: 0 $padding--md;

    @include mq(screen--lg) {
      padding: 0 $padding--lg;
    }

    @include mq(screen--xxl) {
      width: $screen--xxl;
    }

}