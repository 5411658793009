// =================================================
// HOME PAGE CONTENT STYLES
// =================================================

.hp-content {
  padding: 0 0 $padding--xl;

  background: url('img/people-bg.jpg') no-repeat top center;
  background-size: auto 100%;

    @include mq(screen--md) {
      padding: $padding--md 0 $padding--xl;
    }

}

.hp-content .btn-group {
  padding: $padding--lg 0 0;
}