// =================================================
// HERO STYLES
// =================================================

.hero-outer {
  position: relative;
  overflow: hidden;

  border-bottom: 5px solid;

    @include mq(screen--lg) {
      border-bottom: 10px solid;
    }
    
}

.hero-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  opacity: 0.75;

    img {
      @include flex-img;
    }

}

.hero {
  position: relative;
  z-index: 3;
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pg-title {
  font-size: $font-size--md;
  font-weight: $font-weight--bold;
  line-height: $line-height--sm;
  letter-spacing: $kerning--sm;
  color: white;

    @include mq(screen--sm) {
      font-size: $font-size--lg;
    }

    @include mq(screen--lg) {
      font-size: $font-size--xl;
    }

    @include ie-support {
      display: block;
      width: 100%;
    }

}

.hero-text {
  margin: $padding--md 0;

  font-size: $font-size--sm;
  line-height: $line-height--md;

    @include mq(screen--lg) {
      padding: $padding--md 0 $padding--lg;
    }

    @include ie-support {
      display: block;
      width: 100%;
    }

}

// Size variants

.hero--sm {

  .hero-content {
    padding: 0 0 $padding--lg;

      @include mq(screen--sm) {
        flex-direction: row;
        justify-content: space-between;
      }

      @include mq(screen--lg) {
        padding: 0 0 $padding--xl;
      }

  }
  
  .pg-title {
    margin: 0 0 $padding--md;

      @include mq(screen--sm) {
        margin: 0;
      }

  }

}

.hero--lg {
  text-align: center;
  color: white;

    .hero-content {
      padding: $padding--md 0 $padding--lg;

        @include mq(screen--lg) {
          padding: $padding--lg 0 $padding--xl*2;
        }

    }

}