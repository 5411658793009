// =================================================
// FAQS CTA STYLES
// =================================================

.faqs-cta {
  padding: $padding--md 0;

    p {
      font-size: $font-size--xs;
      font-weight: $font-weight--bold;
      color: white;
    }

    a {
      color: $color-brand--green;
      text-decoration: underline;

        &:hover {
          color: $color-brand--blue-light;
        }

    }

}
