// =================================================
// SYMPTOMS LIST STYLES
// =================================================

.symptoms {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  width: 150%;
  mix-blend-mode: multiply;
}

.symptoms-list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  font-family: $font--alt;
  font-size: $font-size--xl;
  text-transform: uppercase;
  line-height: 1;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE 10 and 11
      color: rgba(black, 0.05);
    }

}

.symptoms-list__item {
  margin: 0 0.5rem;

  mix-blend-mode: multiply;
}

.symptoms-list__item:nth-child(3n-2) {
  animation-name: highlight1;
  animation-duration: 16s;
  animation-iteration-count: infinite;
}

.symptoms-list__item:nth-child(3n-1) {
  animation-name: highlight2;
  animation-duration: 16s;
  animation-iteration-count: infinite;
}

.symptoms-list__item:nth-child(3n) {
  animation-name: highlight3;
  animation-duration: 16s;
  animation-iteration-count: infinite;
}

@keyframes highlight1 {
  
  0%, 6.25% {
    color: rgba(black, 0.05);
  }
  
  12.5%, 31.25% {
    color: rgba(black, 0.1);
  }
  
  37.5%, 100% {
    color: rgba(black, 0.05);
  }
  
}

@keyframes highlight2 {
  
  0%, 37.5% {
    color: rgba(black, 0.05);
  }
  
  43.75%, 56.25% {
    color: rgba(black, 0.1);
  }
  
  62.5%, 100% {
    color: rgba(black, 0.05);
  }
  
}

@keyframes highlight3 {
  
  0%, 62.5% {
    color: rgba(black, 0.05);
  }
  
  68.75%, 87.5% {
    color: rgba(black, 0.1);
  }
  
  93.75%, 100% {
    color: rgba(black, 0.05);
  }
  
}
