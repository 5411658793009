// ====================================
// FORM STYLES
// ====================================

.contact-form__row + .contact-form__row {
  margin: $padding--md 0 0;

    @include mq(screen--md) {
      margin: $padding--lg 0 0;
    }

}

.contact-form__label {
  display: block;
  margin: 0 0 1rem;

  font-weight: $font-weight--bold;
  font-size: $font-size--xs;
  color: $color-brand--blue-dark;

    @include mq(screen--lg) {
      font-size: $font-size--sm;
    }

}

// Base styles

.contact-form__row,
.newsletter-form__field {

  input[type=text],
  input[type=tel],
  input[type=email],
	input[type=url],
	input[type=password] {
      width: 100%;
      height: 6rem;
      padding: 0 1rem;

      border: 1px solid $color-brand--blue-dark;
      border-radius: 3px;
      background-color: white;

      font-size: $font-size--xs;

        @include mq(screen--lg) {
          height: 8rem;
          padding: 0 2rem;

          font-size: $font-size--sm;
        }

				&.wpcf7-not-valid {
					border: 3px solid $color-validate--fail;
        }
        
        &:focus {
          outline: 3px solid $color-brand--blue-dark;
          border: none;
        }

  }

  textarea {
    width: 100%;
    height: 25rem;
    padding: 1rem;

    border: 1px solid $color-brand--blue-dark;
    border-radius: 3px;
    background-color: white;

    font-size: $font-size--xs;

      @include mq(screen--lg) {
        height: 30rem;
        padding: 2rem;

        font-size: $font-size--sm;
      }
		
			&.wpcf7-not-valid {
				border: 3px solid $color-validate--fail;
      }
      
      &:focus {
        outline: 3px solid $color-brand--blue-dark;
      }

  }

  select {
      width: 100%;
      height: 5rem;

      border: 1px solid $color-brand--blue-dark;
      background-color: white;

      font-size: $font-size--xs;
  }

  input[type="submit"] {
    
  }

}


// Custom styles

.wpcf7-acceptance {
  font-size: $font-size--xs;
  line-height: $line-height--md;

    label {
      display: block;
      position: relative;
      padding: 0 0 0 2.5rem;
    }
  
    input[type="checkbox"] {
      position: absolute;
      top: 0.5rem;
      left: 0;
      z-index: 1;
    }

}

// Validation

.not-valid {
	display: block;
	padding: $padding--sm;
	margin: 1rem 0 0;

	background-color: $color-validate--fail;

	font-size: $font-size--xs;
	color: white;
}

// CF7 specific

form.wpcf7-form.sent,
form.wpcf7-form.invalid {
  padding: 0 0 $padding--lg;
}

div.wpcf7 .ajax-loader {
	display: none !important;
}

.screen-reader-response {
	display: none;
}

span.wpcf7-not-valid-tip {
	display: block;
	padding: $padding--sm;
	margin: 0;

	background-color: $color-validate--fail;

	font-size: $font-size--xs;
	color: white;
}

div.wpcf7-validation-errors,
div.wpcf7-mail-sent-ng,
div.wpcf7-mail-sent-ok {
	border: none !important;
}

div.wpcf7-response-output {
	margin: 0 !important;
  padding: 0;
  
	font-size: $font-size--xs;
	color: white;
	
		&.wpcf7-validation-errors {
      margin: $padding--md 0 0 !important;
			padding: $padding--sm;
			background-color: $color-validate--fail;
		}

		&.wpcf7-validation-success,
		&.wpcf7-mail-sent-ok {
      margin: $padding--md 0 0 !important;
			padding: $padding--sm;
			background-color: $color-validate--ok;
		}

}

span.wpcf7-list-item {
  margin: 0;
}

.wpcf7-acceptance {
  display: block;
  padding: $padding--md;
  
  background: rgba(white, 0.25);
}