/*!
Theme Name: Think NP-C
Theme URI: http://www.think-npc.com
Author: Abbas Arezoo
Author URI: http://www.think-npc.com
Description: Think NP-C Wordpress theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags:
*/

@import "ui/media-queries";
@import "ui/fonts";
@import "ui/variables";
@import "ui/mixins";
@import "ui/reset";

@import "plugins/tiny-slider";

@import "layout/global";
@import "layout/header";
@import "layout/footer";
@import "layout/layout";
@import "layout/spacing";
@import "layout/themes";

@import "components/buttons";
@import "components/quicklinks";
@import "components/hp-content";
@import "components/std-content";
@import "components/faqs-cta";
@import "components/references";
@import "components/symptoms";
@import "components/hero";
@import "components/modal";
@import "components/content-modules";
@import "components/cta";
@import "components/forms";
@import "components/social";


