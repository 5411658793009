// =================================================
// STANDARD CONTENT STYLES
// =================================================

.std-content {

	& > *:last-child {
		margin-bottom: 0;
	}
	
	// Main headings

	h1, h2 {
		margin: 0 0 $padding--md;

		font-weight: $font-weight--bold;
		font-size: $font-size--lg;
		line-height: $line-height--sm;
		color: $color-brand--blue-dark;
		letter-spacing: $kerning--sm;

			@include mq(screen--lg) {
				font-size: $font-size--xl;
			}

	}

	*+h1,
	*+h2 {
		margin: $padding--lg 0 $padding--md;
	}


	// Sub headings


	h3, h4 {
    margin: 0 0 $padding--md;
		
		font-size: $font-size--xs;
		font-weight: $font-weight--bold;
		color: $color-brand--blue-dark;
		line-height: $line-height--lg;

			@include mq(screen--lg) {
				font-size: $font-size--sm;
			}

	}

	*+h3, *+h4 {
		margin: $padding--lg 0 $padding--md;
	}

	// Small headings

	h5, h6 {
		margin: 0 0 $padding--sm;
		
		font-size: $font-size--xs;
    color: $color-brand--blue-dark;
    line-height: $line-height--lg;
	}

	*+h5, *+h6 {
		margin: $padding--md 0 $padding--sm;
	}

	// Paragraphs

	p,ul,ol {
    font-size: $font-size--xs;

			a {
				color: $color-brand--blue-dark;
        text-decoration: underline;

					&:hover {
						color: $color-brand--blue-dark--hover;
					}

			}

			strong {
        font-weight: $font-weight--bold;
				color: $color-brand--blue-dark;
			}

			em {
				font-style: italic;
				color: $color-brand--blue-dark;
			}
					
	}

	p {
		margin: 0 0 $padding--md;

		line-height: $line-height--lg;
	}

	sup {
		font-size: 50%;
	}

	ul, ol {
		margin: 0 0 $padding--md $padding--md;
	}

	li {
		margin: 0 0 0.5rem;
		
		line-height: $line-height--lg;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style-type: decimal;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
		margin: $padding--lg 0;
	}

	blockquote {
		display: block;
		padding: $padding--sm $padding--md;
		margin: $padding--lg 0 $padding--md;

			@include mq(screen--lg) {
				border-left-width: 5px;
			}

			p {
				margin: 0;

				font-weight: $font-weight--bold;
				font-size: $font-size--sm;
				color: $color-brand--blue-dark;
				line-height: $line-height--lg;

					&:before {
						content: open-quote;
					}

					&:after {
						content: close-quote;
					}

			}

	}

	a {
		color: inherit;
		text-decoration: underline;
	}

	table {
		width: 100% !important;
		margin: 0 0 $padding--md;

    border: 1px solid $color-brand--blue-dark;
		background-color: white;
		
			@include mq(screen--lg) {
				margin: 0 0 $padding--lg;
			}

      th, td {
        max-width: 30rem;
        padding: 1.5rem 1rem;
				text-align: left;
				
        font-size: $font-size--xs;

        border-bottom: 1px solid $color-brand--blue-dark;
      }

      tr:last-child td {
        border: none;
      }

      a {
        color: inherit;
        text-decoration: underline;

          &:hover {
            color: $color-brand--blue-dark--hover;
          }

			}

	}
	
	hr {
		margin: $padding--md 0;

		border: 1px solid $color-ui--grey-light;
		
			@include mq(screen--lg) {
				margin: $padding--lg 0;
			}
	}

}

img.alignleft {
	
	@include mq(screen--xs) {
		width: percentage(1/3);
		float: left;
		margin: 0 $padding--lg $padding--lg 0;
	}

	@include mq(screen--sm) {
		width: 25%;
	}

}

.wp-admin img.alignleft {
	float: none;
}

img.alignright {
	
	@include mq(screen--xs) {
		width: percentage(1/3);
		float: right;
		margin: 0 0 $padding--lg $padding--lg;
	}

	@include mq(screen--sm) {
		width: 25%;
	}

}

.wp-caption {
	width: 100% !important;
	height: auto;
	margin: $padding--lg 0;

		img {
			margin: 0;
		}
		
}

p.wp-caption-text {
	margin: 0;
	padding: $padding--sm;

	color: $color-brand--blue-dark;
	line-height: $line-height--md;

	background-color: $color-ui--grey-light;

		@include mq(screen--sm) {
			padding: $padding--md $padding--sm;
		}
		
}

.video-embed {
	position: relative;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
	margin: $padding--lg 0;

		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

}