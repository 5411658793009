// ====================================
// IMAGES
// ====================================

@mixin bkg-cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

@mixin flex-img {
	display: block;
	width: 100%;
	height: auto;
}

@mixin rev-flex-img {
	display: block;
	width: auto;
	height: 100%;
}

@mixin object-fit {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@mixin object-fit-support {

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { // IE 10 and 11
    background-size: cover;
    background-position: center;

      img {
        opacity: 0;
        transition: none;
      }

  }
  
  @supports (-ms-accelerator:true) { // IE Edge
    background-size: cover;
    background-position: center;

      img {
        opacity: 0;
        transition: none;
      }

  }
		
}

@mixin ie-support {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
	}

	@supports (-ms-accelerator:true) { // IE Edge
		@content;
  }
}

// ====================================
// LAYOUT
// ====================================

@mixin inner-helper {
  width: 100%;
  margin: 0 auto;
  padding: 0 $padding-md;

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
		}

}

@mixin inner-xxl {
  padding: 0 $padding-md;

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

    @include mq(screen-xxl) {
      width: $screen-xxl;
      margin: 0 auto;
    }
}

@mixin inner-xl {
  padding: 0 $padding-md;

    @include mq(screen-lg) {
      padding: 0 $padding-lg;
    }

    @include mq(screen-xl) {
      width: $screen-xl;
      margin: 0 auto;
    }
}

@mixin inner-lg {
  padding: 0 $padding-md;

    @include mq(screen-lg) {
      width: $screen-lg;
      margin: 0 auto;
      padding: 0 $padding-lg;
    }

}

@mixin space-after-xl {
	margin: 0 0 $padding-lg;

		@include mq(screen-lg) {
			margin: 0 0 $padding-xl;
		}

}

@mixin space-after-lg {
	margin: 0 0 $padding-md;

		@include mq(screen-md) {
			margin: 0 0 $padding-lg;
		}

}

@mixin space-after-md {
	margin: 0 0 $padding-sm;

		@include mq(screen-sm) {
			margin: 0 0 $padding-md;
		}

}

@mixin space-after-sm {
	margin: 0 0 $padding-xs;

		@include mq(screen-xs) {
			margin: 0 0 $padding-sm;
		}

}

@mixin inner-space {
	padding: $padding-lg 0;

		@include mq(screen-lg) {
			padding: $padding-xl 0;
		}

}

// ====================================
// TYPE
// ====================================

@function strip-unit-rem($value) {
  @return $value / (1rem);
}

@function strip-unit-px($value) {
  @return (($value / 1px) / 10);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {

  font-size: $min-font-size;
  
    @media screen and (min-width: $min-vw) {
      font-size: calc(#{$min-font-size} + #{strip-unit-rem($max-font-size - $min-font-size)} * ((100vw - #{strip-unit-px($min-vw)+rem}) / #{strip-unit-px($max-vw - $min-vw)}));
    }

    @media screen and (min-width: $max-vw) {
      font-size: $max-font-size;
    }

}

@mixin placeholder {  
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin text-highlight {
	
	&::selection { 
		background: $color-orange;
		color: white;
	}

}

@mixin hero-title-md($color) {
  font-family: $font-extra-light;
  line-height: $line-height-sm;
  letter-spacing: $kerning-sm;
  color: $color;

  @include fluid-type($screen-sm, $screen-xxl, $font-size-lg, $font-size-xxl);
}

@mixin hero-title-sm($color) {
  font-family: $font-extra-light;
  line-height: $line-height-sm;
  letter-spacing: $kerning-sm;
  color: $color;

  @include fluid-type($screen-sm, $screen-xxl, $font-size-md, $font-size-xl);
}

@mixin section-title($color: $color-purple) {  
  font-family: $font-bold;
  color: $color;
  line-height: $line-height-lg;
  text-transform: uppercase;
  font-size: $font-size-xs;

    @include mq(screen-lg) {
        font-size: $font-size-sm;
    }

}