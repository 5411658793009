// =================================================
// REFERENCES STYLES
// =================================================

.references-outer {
  display: none;
  padding: $padding--lg 0;

    &.is-open {
      display: block;
    }

}

.references__title {
  margin: 0 0 $padding--md;

  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  color: $color-brand--blue-dark;
  line-height: $line-height--lg;

    @include mq(screen--lg) {
      font-size: $font-size--sm;
    }

}

.reference {
  position: relative;
  padding: 0 0 0 $padding--md;
}

.reference + .reference {
  margin: $padding--md 0 0;
}

.reference__number {
  position: absolute;
  top: 0.2rem;
  left: 0;

  font-size: $font-size--xxs;
}

.reference__info {
  font-size: $font-size--xxs;
  line-height: $line-height--md;
}

.close-references {
  margin: $padding--lg 0 0;
}