// Brand colors

$color-brand--blue-light: #86d0f4;
$color-brand--blue-dark: #00425c;
$color-brand--purple-light: #a14593;
$color-brand--purple-dark: #77326d;
$color-brand--green: #04a64b;

$color-brand--blue-light--hover: darken($color-brand--blue-light, 10%);
$color-brand--blue-dark--hover: darken($color-brand--blue-dark, 5%);
$color-brand--purple-light--hover: darken($color-brand--purple-light, 5%);
$color-brand--purple-dark--hover: darken($color-brand--purple-dark, 5%);
$color-brand--green--hover: darken($color-brand--green, 5%);

$color-brand--blue-light--opaque: rgba($color-brand--blue-light, 0.15);
$color-brand--blue-dark--opaque: rgba($color-brand--blue-dark, 0.15);
$color-brand--purple-light--opaque: rgba($color-brand--purple-light, 0.1);
$color-brand--purple-dark--opaque: rgba($color-brand--purple-dark, 0.1);
$color-brand--green--opaque: rgba($color-brand--green, 0.15);

$color-ui--grey-light: #eaeaea;
$color-ui--grey-mid: #5A5A5A;

$darken: 5%;

$color-validate--ok: rgb(0, 201, 0);
$color-validate--fail: red;

$color-social--youtube: #CD0C24;
$color-social--twitter: #009CDC;
$color-social--facebook: #204889;

// Animation

$transition--lg: 0.33s ease-out;
$transition--md: 0.25s ease-out;
$transition--sm: 0.1s ease-out;

// Fonts
$font--alt: 'Roboto Condensed';
$font-weight--bold: 700;

$font-size--xxl: 6rem;
$font-size--xl: 4.5rem;
$font-size--lg: 3.3rem;
$font-size--md: 2.4rem;
$font-size--sm: 2.1rem;
$font-size--xs: 1.8rem;
$font-size--xxs: 1.6rem;

$kerning--md: -0.2rem;
$kerning--sm: -0.1rem;

$line-height--lg: 1.6;
$line-height--md: 1.4;
$line-height--sm: 1.2;

// UI styles

$padding-global: 5rem;

$padding--xl: $padding-global*2;
$padding--lg: $padding-global;
$padding--md: $padding-global/2;
$padding--sm: $padding-global/4;

$border-radius--lg: 1rem;
$border-radius--md: 0.5rem;

$border-btn--width: 3px;
$border-panel--width: 5px;