// =================================================
// SHARE MODAL STYLES
// =================================================

.modal-outer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  opacity: 0;
  visibility: hidden;

  background-color: rgba(black,0.75);

  transition: $transition--sm;

    &.is-open {
      z-index: 10000;
      opacity: 1;
			visibility: visible;
    }

    > button {
      position: absolute;
      top: $padding--md;
      right: $padding--md;

        @include mq(screen--lg) {
          top: $padding--lg;
          right: $padding--lg;
        }

    }

}

// Share modal

.share-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 20rem;
  padding: $padding--lg;

  background-color: white;
  border: 3px solid $color-brand--blue-dark;

    @include mq(screen--xxs) {
      width: 30rem;
    }

    .social-nav__list {
      margin: 0 0 $padding--md;
    }

    .social-nav__item {
      margin: 0 0.5rem;
    }

}

.share-modal__title {
  margin: 0 0 $padding--md;

  font-weight: $font-weight--bold;
  font-size: $font-size--sm;
  color: $color-brand--blue-dark;
}

// Video modal

.video-modal {
  position: relative;
  padding-bottom: 45%;
  width: 90%;
  height: 0;
  overflow: hidden;

    @include mq(screen--lg) {
      width: 75%;
    }

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}