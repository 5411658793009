// ====================================
// SOCIAL STYLES
// ====================================

.social-cta {
  padding: $padding--md;
  border: 1px solid;
}

.social-cta__item + .social-cta__item {
  margin: $padding--sm 0 0;
}

.social-cta__link {
  display: flex;
  align-items: center;

  font-size: $font-size--xs;
  color: $color-brand--blue-dark;

    @include mq(screen--sm) {
      font-size: $font-size--sm;
    }

    &:hover {
      text-decoration: underline;
    }

}

.social-cta__icon {
  display: block;
  width: 4rem;
  height: 4rem;
  margin: 0 $padding--sm 0 0;
  
    svg {
      width: 4rem;
      height: 4rem;

      fill: white;
    }

}

.social-cta__icon--facebook {
  background-color: $color-social--facebook;
}

.social-cta__icon--twitter {
  background-color: $color-social--twitter;
}

.social-cta__icon--youtube {
  background-color: $color-social--youtube;
}