// =================================================
// GLOBAL STYLES AND HELPERS
// =================================================

html, body {
  margin: 0;
  padding: 0;
}

.no-scroll {
  overflow: hidden;
}

button:focus {
  outline: none;
}

iframe {
  border: none;
}

// Text alignment

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

// Background colours

.bg--blue-light { background-color: $color-brand--blue-light; }
.bg--blue-dark { background-color: $color-brand--blue-dark; }
.bg--purple-light { background-color: $color-brand--purple-light; }
.bg--purple-dark { background-color: $color-brand--purple-dark; }
.bg--green { background-color: $color-brand--green; }

// Page title