// =================================================
// QUICKLINKS STYLES
// =================================================

.quicklinks {
  margin: -$padding--sm;

    @include mq(screen--xs) {
      display: flex;
    }

}

.quicklink__item {
  padding: $padding--sm;

    @include mq(screen--xs) {
      width: percentage(1/3);
    }

}

.quicklink {
  display: block;
  padding: $padding--lg $padding--md;

  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  text-align: center;
  line-height: $line-height--sm;

  border: 3px solid;

    @include mq(screen--xs) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    @include mq(screen--lg) {
      padding: $padding--lg $padding--xl;

      font-size: $font-size--md;
    }

}

// Colour variants

.home {

  .quicklink__item:nth-child(1) .quicklink {
    color: $color-brand--blue-light;

      &:hover {
        color: $color-brand--blue-light--hover;
      }

  }

  .quicklink__item:nth-child(2) .quicklink {
    color: $color-brand--green;

      &:hover {
        color: $color-brand--green--hover;
      }

  }
  
  .quicklink__item:nth-child(3) .quicklink {
    color: $color-brand--blue-dark;

      &:hover {
        color: $color-brand--blue-dark--hover;
      }

  }

}