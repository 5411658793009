// =================================================
// STRUCTURE
// =================================================

html {
  font-size: 57.5%;
      
      @include mq(screen-lg) {
          font-size: 62.5%;
      }
      
}

body {
  margin: 0;
}

// =================================================
// FONT RESET
// =================================================

body,
input,
select,
option,
button,
textarea {
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  color: $color-ui--grey-mid;
}

h1,
h2,
h3,
h4,
h5,
h6 {  
  margin: 0;
  padding: 0;
  font-weight: normal;
}

strong {
  font-weight: normal;
}

ul, ol {
  list-style-type: none;
}

a {
  text-decoration: none;
}

// =================================================
// STANDARD RESETS
// =================================================

* {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

a {
  background-color: transparent;
  cursor: pointer;
}

a:active,
a:hover {
  outline: 0;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input
select,
option,
button,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
}

p,ul,ol,blockquote {
  margin: 0;
  padding: 0;
}

svg, i {
  display: block;
}

svg {
  width: inherit;
  height: inherit;
}

.no-object-fit .object-fit {
  @include object-fit-support;
}