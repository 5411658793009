// =================================================
// THEME STYLES
// =================================================

.theme--blue-light {
  
.hero-outer {
  background-color: $color-brand--blue-light;
  border-color: $color-brand--blue-light--hover;
}

.quote__text {
  color: $color-brand--blue-dark;
}

.highlight-panel,
.accordion__item__content,
.accordion__item__toggle:hover,
.contact-form-outer {
  background-color: $color-brand--blue-light--opaque;
}

.accordion__item__toggle {
  color: $color-brand--blue-dark;

    &:focus {
      background-color: $color-brand--blue-dark;
    }
    
}

.accordion,
.accordion__item + .accordion__item {
  border-color: $color-brand--blue-dark;
}

.quicklink {
  color: $color-brand--blue-dark;

    &:hover {
      background: $color-brand--blue-light--opaque;
    }

}

.download__link {
  color: $color-brand--blue-dark;

    &:hover {
      background: $color-brand--blue-light--opaque;
    }

}

.social-cta {
  border-color: $color-brand--blue-light;
}


}

.theme--blue-dark {
  
.hero-outer {
  background-color: $color-brand--blue-dark;
  border-color: $color-brand--blue-dark--hover;
}

.quote__text {
  color: $color-brand--blue-dark;
}

.highlight-panel,
.accordion__item__content,
.accordion__item__toggle:hover,
.contact-form-outer {
  background-color: $color-brand--blue-light--opaque;
}

.accordion__item__toggle {
  color: $color-brand--blue-dark;

    &:focus {
      background-color: $color-brand--blue-dark;
    }
    
}

.accordion,
.accordion__item + .accordion__item {
  border-color: $color-brand--blue-dark;
}

.quicklink {
  color: $color-brand--blue-dark;

    &:hover {
      background: $color-brand--blue-light--opaque;
    }

}

.download__link {
  color: $color-brand--blue-dark;

    &:hover {
      background: $color-brand--blue-light--opaque;
    }

}

.social-cta {
  border-color: $color-brand--blue-dark;
}
  

}

.theme--purple-light,
.home {
  
.hero-outer {
  background-color: $color-brand--purple-light;
  border-color: $color-brand--purple-light--hover;
}

.quote__text {
  color: $color-brand--purple-light;
}

.highlight-panel,
.accordion__item__content,
.accordion__item__toggle:hover,
.contact-form-outer {
  background-color: $color-brand--purple-light--opaque;
}

.accordion__item__toggle {
  color: $color-brand--purple-light;

    &:focus {
      background-color: $color-brand--purple-light;
    }
    
}

.accordion,
.accordion__item + .accordion__item {
  border-color: $color-brand--purple-light;
}

.quicklink {
  color: $color-brand--purple-light;

    &:hover {
      background: $color-brand--purple-light--opaque;
    }

}

.download__link {
  color: $color-brand--purple-light;

    &:hover {
      background: $color-brand--purple-light--opaque;
    }

} 

.social-cta {
  border-color: $color-brand--purple-light;
}

}

.theme--purple-dark {
  
.hero-outer {
  background-color: $color-brand--purple-dark;
  border-color: $color-brand--purple-dark--hover;
}

.quote__text {
  color: $color-brand--purple-dark;
}

.highlight-panel,
.accordion__item__content,
.accordion__item__toggle:hover,
.contact-form-outer {
  background-color: $color-brand--purple-dark--opaque;
}

.accordion__item__toggle {
  color: $color-brand--purple-dark;

    &:focus {
      background-color: $color-brand--purple-dark;
    }
    
}

.accordion,
.accordion__item + .accordion__item {
  border-color: $color-brand--purple-dark;
}

.quicklink {
  color: $color-brand--purple-dark;

    &:hover {
      background: $color-brand--purple-dark--opaque;
    }

}

.download__link {
  color: $color-brand--purple-dark;

    &:hover {
      background: $color-brand--purple-dark--opaque;
    }

} 

.social-cta {
  border-color: $color-brand--purple-dark;
}

}

.theme--green {
  
.hero-outer {
  background-color: $color-brand--green;
  border-color: $color-brand--green--hover;
}

.quote__text {
  color: $color-brand--green;
}

.highlight-panel,
.accordion__item__content,
.accordion__item__toggle:hover,
.contact-form-outer {
  background-color: $color-brand--green--opaque;
}

.accordion__item__toggle {
  color: $color-brand--green;

    &:focus {
      background-color: $color-brand--green;
    }
    
}

.accordion,
.accordion__item + .accordion__item {
  border-color: $color-brand--green;
}

.quicklink {
  color: $color-brand--green;

    &:hover {
      background: $color-brand--green--opaque;
    }

}

.download__link {
  color: $color-brand--green;

    &:hover {
      background: $color-brand--green--opaque;
    }

} 

.social-cta {
  border-color: $color-brand--green;
}

}

