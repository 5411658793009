// =================================================
// CALL TO ACTION STYLES
// =================================================

.cta {
  
  @include mq(screen--sm) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

}

.cta__text,
.cta__img {
  @include mq(screen--sm) {
    width: 47.5%;
  }
}

.cta__text {
  .btn-group {
    @include mq(screen--sm) {
      justify-content: flex-start;
    }
  }
}

.cta__text__title {
  margin: 0 0 $padding--md;

  font-weight: $font-weight--bold;
  font-size: $font-size--lg;
  line-height: $line-height--sm;
  color: $color-brand--blue-dark;
  letter-spacing: $kerning--sm;

    @include mq(screen--lg) {
      margin: 0 0 $padding--lg;

      font-size: $font-size--xl;
    }

}

.cta__img img {
  @include flex-img;
}