// =================================================
// PAGE HEADER STYLES
// =================================================

.pg-header-outer {
  position: relative;
  z-index: 4;
}

.pg-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $padding--md $padding--md $padding--lg;

    @include mq(screen--sm) {
      flex-direction: row;
      justify-content: space-between;
    }

    @include mq(screen--lg) {
      width: $screen--lg;
      padding: $padding--lg $padding--lg $padding--xl;
      margin: 0 auto;
    }

}

.pg-header__logo {
  display: block;
  width: 20rem;
  height: 9rem;
  margin: 0 0 $padding--md;

    @include mq(screen--sm) {
      margin: 0;
    }

    svg {
      width: 20rem;
      height: 9rem;
    }

}

// Main navigation

.pg-header__nav {
  
  @include mq(screen--md) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @include mq(screen--xl) {
    position: relative;
  }

}

.main-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  font-size: $font-size--xxs;
  font-weight: $font-weight--bold;

    @include mq(screen--sm) {
      font-size: $font-size--xs;
    }

    @include mq(screen--lg) {
      font-size: $font-size--sm;
    }

    li {
      margin: 1rem;

        @include mq(screen--sm) {
          margin: 0 0 0 2rem;
        }

        @include mq(screen--lg) {
          margin: 0 0 0 4rem;
        }

    }

    a {
      color: white;

        &:hover {
          text-decoration: underline;
        }
        
    }

}

.current-menu-item a {
  text-decoration: underline;
}

// Social navigation

.social-nav {
  display: none;

    @include mq(screen--md) {
      display: block;
      margin: 0 0 0 $padding--md;
    }

    @include mq(screen--xl) {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);

      margin: 0 0 0 $padding--lg;
    }

}

.social-nav__list {
  display: flex;
}

.social-nav__item {
  margin: 0 0 0 1rem;
}

.social-nav__link {
  display: block;
  width: 4rem;
  height: 4rem;
  
    svg {
      width: 4rem;
      height: 4rem;

      fill: white;
    }

}

.social-nav__link--facebook {
  background-color: $color-social--facebook;
}

.social-nav__link--twitter {
  background-color: $color-social--twitter;
}

.social-nav__link--youtube {
  background-color: $color-social--youtube;
}