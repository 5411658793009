// =================================================
// CONTENT MODULE STYLES
// =================================================

// Section header

.cnt-header {
  margin: 0 0 $padding--md;
}

.cnt-header--lg {
  
  @include mq(screen--lg) {
    margin: 0 0 $padding--lg;
  }

}

.cnt-header__title {
  font-weight: $font-weight--bold;
  font-size: $font-size--md;
  line-height: $line-height--sm;
  color: $color-brand--blue-dark;
  letter-spacing: $kerning--sm;

    @include mq(screen--lg) {
      font-size: $font-size--lg;
    }

    @include ie-support {
      display: block;
      width: 100%;
    }

}

// Quote

.quote__text {
  font-size: $font-size--sm;
  font-weight: $font-weight--bold;
  font-style: italic;
  text-align: center;
  line-height: $line-height--sm;

    @include mq(screen--lg) {
      font-size: $font-size--lg;
    }

}

// Full width story

.highlight-panel {
  padding: $padding--lg 0;
  margin: 0 0 $padding--lg;

    @include mq(screen--lg) {
      padding: $padding--xl 0;
      margin: 0 0 $padding--xl;
    }

    .std-content {
      color: $color-brand--blue-dark;
    }

}

// Story columns

.video-story-outer .std-content {
  color: $color-brand--blue-dark;
}

.video-grid {
  margin: -$padding--md;
  
  @include mq(screen--xs) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

}

.video-grid__item {
  padding: $padding--md;

    @include mq(screen--xs) {
      width: 50%;
    }

    .video-embed {
      margin: 0 0 $padding--md;

        @include mq(screen--lg) {
          margin: 0 0 $padding--lg;
        }
        
    }

}

.video-grid__title {
  margin: 0 0 $padding--md;
		
  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  color: $color-brand--blue-dark;
  line-height: $line-height--lg;

    @include mq(screen--lg) {
      font-size: $font-size--sm;
    }

    @include ie-support {
      display: block;
      width: 100%;
    }

}

// Image gallery

.img-gallery__img {
  
  img {
    @include flex-img;
  }

}

.img-gallery__controls {
  display: flex;
  justify-content: center;
}

.img-gallery__control {
  display: block;
  width: 5rem;
  height: 5rem;
  padding: 1.5rem;

  background: none;
  border: none;

    svg {
      width: 2rem;
      height: 2rem;

      fill: $color-brand--blue-dark;
    }

}

.img-gallery__control:hover svg {
  fill: $color-brand--blue-dark--hover;
}

.img-gallery__control--prev {
  
  svg {
    transform: rotate(-180deg);
  }

}

// Accordion

.accordion {
  border: 1px solid;
}

.accordion__item__toggle {
  position: relative;
  width: 100%;
  padding: 1.5rem 4rem 1.5rem 1.5rem;

  background: none;
  border: none;

  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  text-align: left;

    svg {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      z-index: 1;
      width: 1rem;
      height: 1rem;

      fill: currentColor;

      transform: translateY(-50%) rotate(90deg);
    }

    &:focus {
      color: white;

        svg {
          transform: translateY(-50%) rotate(-90deg);
        }

    }
}

.accordion__item + .accordion__item {
  border-top: 1px solid;
}

.accordion__item__content {
  display: none;
  padding: $padding--md 1.5rem;

    &.is-open {
      display: block;
    }
    
}

// Image grid

.img-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1rem;
}

.img-grid__item {
  display: block;
  width: 50%;
  padding: 1rem;

  border: none;
  overflow: hidden;

    &:focus {
      outline: none;
    }

    &:hover {
      
      .img-grid__img {
        opacity: 0.8;
      }

    }

    @include mq(screen--sm) {
      width: 25%;
    }

}

.img-grid__img {
  display: block;
  width: 100%;
  height: auto;

  transition: opacity $transition--md;
}

.modal-img-outer {
  width: 75%;
  height: 90%;
}

.modal-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// Downloads

.download + .download {
  border-top: 1px solid $color-ui--grey-light;
}

.download__link {
  position: relative;
  display: block;
  padding: 1.5rem 4rem 1.5rem 1rem;

  font-size: $font-size--xs;
  font-weight: $font-weight--bold;

    svg {
      position: absolute;
      top: 50%;
      right: 1.5rem;
      z-index: 1;
      width: 1rem;
      height: 1rem;

      fill: currentColor;

      transform: translateY(-50%) rotate(90deg);
    }

}

// Divider

.divider {
  display: block;
  width: 100%;
  height: 1px;
  margin: $padding--lg 0;

  background-color: $color-ui--grey-light;

    @include mq(screen--lg) {
      margin: $padding--xl 0;
    }
    
}