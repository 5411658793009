// =================================================
// BUTTON STYLES
// =================================================

.btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -$padding--sm;
}

.btn-group__item {
  padding: $padding--sm;
}

.btn {
  display: inline-block;
  padding: 1rem 1.5rem;

  font-family: $font--alt;
  font-size: $font-size--xs;
  text-transform: uppercase;

  border: 3px solid;
  background: none;
}

.icon-btn {
  position: relative;
  display: inline-block;
  padding: 1rem 1.5rem 1rem 4.5rem;

  font-family: $font--alt;
  font-size: $font-size--xs;
  text-transform: uppercase;

  border: 3px solid;
  background: none;

    svg {
      position: absolute;
      top: 50%;
      left: 1.5rem;
      transform: translateY(-50%);
      z-index: 1;
      
      width: 1.5rem;
      height: 1.5rem;
      fill: currentColor;
    }

}

.btn--blue-dark {
  color: $color-brand--blue-dark;

  border-color: $color-brand--blue-dark;

    &:hover {
      background-color: $color-brand--blue-dark;

      color: white;
    }

}

.btn--white {
  color: white;

  border-color: white;

    &:hover,
    &.is-active {
      background-color: white;

      color: $color-brand--blue-dark;
    }

}

.btn--white-fill {
  background-color: white;
  border-color: white;

  color: $color-brand--purple-light;

    &:hover,
    &.is-active {
      color: $color-brand--blue-dark;
    }

}

// Text buttons

.text-btn {
  padding: none;

  border: none;

  font-size: $font-size--xs;
  font-weight: $font-weight--bold;
  color: $color-brand--blue-dark;
  text-decoration: underline;

    &:hover {
      color: $color-brand--purple-light;
    }

}