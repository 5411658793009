// =================================================
// PAGE FOOTER STYLES
// =================================================

.pg-footer-outer {
  padding: $padding--lg 0;

  background-color: $color-brand--blue-dark--hover;

    @include mq(screen--lg) {
      padding: $padding--xl 0;
    }

}

.pg-footer {
  display: flex;
  flex-wrap: wrap;
}

.pg-footer__logos {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  //margin: -$padding--md;

    @include mq(screen--xs) {
      flex-direction: row;
      justify-content: center;
    }

}

.pg-footer__logo {
  display: block;
  padding: $padding--sm;

    svg {
      width: 20rem;
      height: 9rem;
    }

    img {
      display: block;
      width: 12rem;
      height: auto;
    }

}

.footer-nav {
  width: 100%;
  margin: $padding--lg 0;

    @include mq(screen--lg) {
      margin: $padding--xl 0;
    }
  
}

.footer-nav__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: -1rem;

  font-size: $font-size--xxs;

    li {
      padding: 1rem;
    }

    a {
      color: white;

        &:hover {
          text-decoration: underline;
        }
        
    }

}

.pg-footer__options {
  display: flex;
  flex-direction: column;
  width: 100%;

  text-align: center;

    @include mq(screen--xs) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    > * {
      margin: 1rem;
    }

    button:last-of-type {
      order: 2;
    }

    p {
      order: 3;

      font-size: $font-size--xxs;
      color: white;

        @include mq(screen--xs) {
          order: 2;
        }

    }

}

